@font-face {
  font-family: 'Almoni Tzar AAA';
  src: url(./almoni-tzar-aaa-regular.otf);
}

:root {
  --darkest-grey: #2e3030;
  --darker-grey: #414042;
  --grey: #949191;
  --lighter-grey: #f6f6f6;
  --red: rgb(255, 60, 65);
  --soft-red: rgb(235, 215, 215);
  --blue: rgb(60, 95, 235);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Almoni Tzar AAA', Arial !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  color: var(--lighter-grey);
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.err-txt {
  margin: 0;
  text-align: start;
  color: red;
  font-weight: bold;
  font-size: 0.9rem;
}

/* .forceRtl {
  direction: rtl;
} */
.forceRtl .MuiInputLabel-outlined {
  transform: translate(-14px, 20px) scale(1) !important;
}
.forceRtl .MuiInputLabel-shrink {
  transform-origin: top right !important;
  transform: translate(-14px, -6px) scale(0.75) !important;
}
.forceRtl .MuiInputLabel-formControl {
  left: unset !important;
  right: 0 !important;
}
.forceRtl .MuiInputBase-root {
  text-align: right !important;
}
.forceRtl .MuiInputBase-root * {
  text-align: right !important;
}
.forceRtl .MuiSelect-iconOutlined {
  right: unset !important;
  left: 0 !important;
}
.forceRtl .MuiMenuItem-root {
  justify-content: flex-end !important;
}

.forceRtl .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
}
.forceRtl .MuiAutocomplete-root .MuiInputLabel-shrink {
  margin-right: unset !important;
}
/* .forceRtl .MuiAutocomplete-popper {
  inset: 0px !important;
} */
.forceRtl .MuiAutocomplete-endAdornment,
.forceRtl .MuiSelect-icon {
  right: 88% !important;
}

.PhoneInput {
  height: unset !important;
}

.MuiSelect-select {
  padding: 0.2rem 0.5rem 0.2rem 0 !important;
}

.MuiInputLabel-shrink {
  color: var(--red) !important;
}
.MuiInputBase-root svg {
  fill: var(--red) !important;
}
.MuiInput-root:after {
  border-bottom: 2px solid var(--red) !important;
}

.forceRtl input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(15%) sepia(73%) saturate(7235%) hue-rotate(352deg) brightness(89%) contrast(85%);
}
input[type='date']:disabled::-webkit-calendar-picker-indicator {
  visibility: visible !important;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(0%) !important;
}

.MuiAutocomplete-clearIndicator {
  display: none !important;
}
